$border-radius: 3px !default;
$border-radius-lg: 6px !default;
$border-radius-sm: 2px !default;

$input-btn-focus-width: .2rem !default;
$input-btn-focus-color: rgba(#95a5a6, .25) !default;
$input-focus-border-color: #95a5a6 !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

@use "sass:math";

@import "~bootstrap/scss/bootstrap";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~suneditor/src/assets/css/suneditor";
@import "~suneditor/src/assets/css/suneditor-contents";
@import "~bootstrap-daterangepicker/daterangepicker";
@import "variables";
@import "fonts";
@import "mixins";
@import "packages";
