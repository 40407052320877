@import "~bootstrap/scss/variables";

// Sizes
$header-height: 150px;
$aside-width: 250px;
$footer-height: 50px;

// Brand colors
$brand-primary-color: #00ddbf;
$brand-secondary-color: #5a48cc;
$brand-secondary-light-color: #a682ff;
$brand-alt-color: #5887ff;
$brand-dark-color: #353a3f;

// Green colors
$color-turquoise: #1ABC9C;
$color-green-sea: #16A085;
$color-emerald: #2ECC71;
$color-nephritis: #27AE60;

// Blue colors
$color-peter-river: #3498DB;
$color-belize-hole: #2980B9;
$color-wet-asphalt: #34495E;
$color-midnight-blue: #2C3E50;

// Purple colors
$color-amethyst: #9B59B6;
$color-wisteria: #8E44AD;

// Yellow colors
$color-sunflower: #F1C40F;

// Orange colors
$color-orange: #F39C12;
$color-carrot: #E67E22;
$color-pumpkin: #D35400;

// Red colors
$color-alizarin: #E74C3C;
$color-pomegranate: #C0392B;

// Gray colors
$color-clouds: #ECF0F1;
$color-silver: #BDC3C7;
$color-concrete: #95A5A6;
$color-asbestos: #7F8C8D;

// App colors
$text-color: #444;
$header-bg-color: $brand-dark-color;
$aside-bg-color: $light;
$footer-bg-color: $color-silver;
$footer-text-color: $white
