@mixin pr {
  position: relative;
}

@mixin pa {
  position: absolute;
}

@mixin pf {
  position: fixed;
}

@mixin dn {
  display: none;
}

@mixin db {
  display: block;
}

@mixin df {
  display: flex;
}

@mixin di {
  display: inline;
}

@mixin dib {
  display: inline-block;
}

@mixin fl {
  float: left;
}

@mixin fr {
  float: right;
}

@mixin fn {
  float: none;
}

@mixin mp-reset {
  margin: 0;
  padding: 0;
}

@mixin w100 {
  width: 100%;
}

@mixin h100 {
  height: 100%;
}

@mixin wh100 {
  @include w100;
  @include h100;
}

@mixin calcW($arg) {
  width: -webkit-calc(#{$arg});
  width: -moz-calc(#{$arg});
  width: calc(#{$arg});
}

@mixin calcH($arg) {
  height: -webkit-calc(#{$arg});
  height: -moz-calc(#{$arg});
  height: calc(#{$arg});
}

@mixin calcT($arg) {
  top: -webkit-calc(#{$arg});
  top: -moz-calc(#{$arg});
  top: calc(#{$arg});
}

@mixin transitions($transition...) {
  transition: $transition;
  -moz-transition: $transition;
  -webkit-transition: $transition;
  -o-transition: $transition;
}

@mixin transform-origin($value) {
  transform-origin: $value;
  -moz-transform-origin: $value;
  -webkit-transform-style: $value;
  -o-transform-origin: $value;
}

@mixin transform($value) {
  transform: $value;
  -moz-transform: $value;
  -webkit-transform: $value;
  -o-transform: $value;
}

@mixin bg-size($arg: cover) {
  background-size: $arg;
  -moz-background-size: $arg;
  -webkit-background-size: $arg;
  -o-background-size: $arg;
}

@mixin box-shadow($shadow...) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  -moz-opacity: $opacity;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
  filter: alpha(opactiy=($opacity * 100));
}
