@import "bootstrap";

html {
  height: 100%;
}

body {
  background-color: $white;
  font-family: $main-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: $header-height 0 $footer-height;
  @include w100;
  @include calcH(100% - #{$header-height + $footer-height});
  color: $text-color;

  >#root {
    @include pr;
    @include db;
    @include w100;
    height: 100%;

    .loading-indicator {
      @include pr;
      @include db;
      @include w100;
      height: 32px;

      &.absolute {
        @include pa;
        top: 0;
        left: 0;
        @include h100;
        z-index: 999;
      }

      &::before {
        @include pa;
        @include db;
        @include wh100;
        background-color: rgba(255, 255, 255, .5);
        content: '';
        z-index: 1;
      }

      &::after {
        @include pa;
        @include db;
        top: 50%;
        left: 50%;
        margin: -16px 0 0 -16px;
        width: 32px;
        height: 32px;
        background: transparent url('../img/loading.svg') center center no-repeat;
        @include bg-size;
        content: '';
        z-index: 2;
      }

    }

    .loading-block {
      &::before {
        @include pa;
        @include db;
        @include wh100;
        background-color: rgba(255, 255, 255, .5);
        content: '';
        z-index: 998;
      }

      &::after {
        @include pa;
        @include db;
        top: 50%;
        left: 50%;
        margin: -16px 0 0 -16px;
        width: 32px;
        height: 32px;
        background: transparent url('../img/loading.svg') center center no-repeat;
        @include bg-size;
        content: '';
        @include opacity(.7);
        z-index: 999;
      }
    }

    .btn {
      &.process {
        cursor: progress;
      }
    }

    >header {
      @include pf;
      @include db;
      top: 0;
      left: 0;
      @include w100;
      height: $header-height;
      z-index: 4;
      border: 2px solid $color-asbestos;

      >nav.navbar {
        padding-left: 10px;
        padding-right: 10px;
        @include h100;

        >button.aside-toggle {
          @include pa;
          top: 40px;
          left: 10px;
          padding: 4px;
          width: 40px;
          height: 40px;
          background-color: $brand-dark-color;
          border: none;
          @include border-radius($border-radius);
          @include box-shadow(none);
          color: $white;
          outline: none;
          cursor: pointer;
          @include transitions(all .3s linear);

          >svg {
            width: 32px;
            height: 32px;
            color: darken($white, 10%);
            @include transitions(all .3s linear);
          }

          &:hover {
            background-color: darken($brand-dark-color, 2%);

            >svg {
              color: $white;
            }
          }
        }

        >a.navbar-brand {
          @include pa;
          top: 7px;
          left: 55px;
          padding: 10px;
          height: 40px;
          @include border-radius($border-radius);
          @include transitions(all .3s linear);

          >img {
            height: 20px;
            @include transitions(all .3s linear);
          }

          &:hover {
            background-color: darken($brand-dark-color, 2%);
          }
        }

        >.user-actions {
          @include pa;
          right: 10px;
          @include clearfix;
          background-color: $brand-dark-color;

          >.dropdown {
            @include fl;

            >a {
              padding: 10px 12px;
              @include border-radius($border-radius);
              text-decoration: none;
              color: darken($white, 15%);
              @include transitions(all .3s linear);

              >svg {
                margin: -2px 5px 0 0;
                @include transitions(all .3s linear);
              }

              >span.badge {
                @include pa;
                top: -10px;
                right: -10px;
                @include transitions(all .3s linear);
              }

              &:hover {
                background-color: darken($brand-dark-color, 2%);
                color: $white;
              }
            }

            &.show {
              >a {
                background-color: darken($brand-dark-color, 5%);
                color: $white;
              }
            }

            >.dropdown-menu {
              right: -1px;
              margin-top: 15px !important;
              border-top: none;
              @include border-top-radius(0);
              @include box-shadow(0 2px 10px -3px rgba(0, 0, 0, .25));

              >.dropdown-item {
                color: lighten($text-color, 10%);
              }
            }
          }

          >.notifications {
            margin-right: 5px;
            z-index: 2;

            >a {
              >svg {
                margin-right: 0;
              }
            }

            >.dropdown-menu {
              width: 250px;

              >.dropdown-item {
                padding-right: 42px;
                white-space: inherit;

                >span.badge {
                  @include pa;
                  top: 9px;
                  right: 12px;
                }
              }
            }
          }

          >.user {
            z-index: 1;
          }
        }
      }
    }

    >aside {
      @include pf;
      @include db;
      top: 0;
      left: 0;
      padding: $header-height 0 $footer-height;
      width: $aside-width;
      @include h100;
      @include transitions(all .3s ease);
      z-index: 2;

      >.scrollbar-container {
        z-index: 2;
      }

      ul.menu {
        @include mp-reset;
        padding: 5px;
        z-index: 1;

        >li {
          @include pr;
          @include mp-reset;
          margin-bottom: 2px;

          >span.submenu-opener {
            @include pa;
            @include db;
            top: 11px;
            right: 5px;
            width: 20px;
            height: 20px;
            font-size: 20px;
            line-height: 16px;
            text-align: center;
            color: lighten($text-color, 10%);
            cursor: pointer;
            @include transition(all .3s linear);
            @include transform-origin(center);
            z-index: 2;
          }

          &:hover {
            >span.submenu-opener {
              color: darken($color-turquoise, 5%);
            }

            >a {
              background-color: $color-clouds;
              border-color: darken($color-clouds, 3%);
              color: darken($color-turquoise, 5%);
            }
          }

          >a {
            @include pr;
            @include db;
            padding: 8px 12px;
            border: 1px solid $aside-bg-color;
            @include border-radius($border-radius);
            text-decoration: none;
            color: $text-color;
            @include transition(all .3s linear);
            z-index: 1;

            >svg {
              @include pa;
              @include db;
              top: 11px;
              left: 12px;
              @include transition(all .3s linear);
            }

            >span {
              @include pr;
              @include db;
              padding-left: 23px;
              @include transition(all .3s linear);
            }

            &:hover {
              background-color: $color-clouds;
              border-color: darken($color-clouds, 3%);
              color: darken($color-turquoise, 5%);
            }

            &.active {
              background-color: $color-turquoise;
              border-color: $color-turquoise;
              color: $white;

              &:hover {
                background-color: darken($color-turquoise, 3%);
                border-color: darken($color-turquoise, 3%);
                color: $white;
              }
            }
          }

          >ul {
            @include mp-reset;
            height: 0;
            border: 1px solid transparent;
            overflow: hidden;
            visibility: hidden;
            @include opacity(0);
            @include transition(all .3s linear);

            z-index: 1;

            >li {
              @include mp-reset;

              >a {
                @include pr;
                @include db;
                padding: 8px 12px;
                @include border-radius($border-radius);
                font-size: 15px;
                text-decoration: none;
                color: $text-color;
                @include transition(all .3s linear);

                >svg {
                  @include pa;
                  @include db;
                  top: 11px;
                  left: 12px;
                  @include transition(all .3s linear);
                }

                >span {
                  @include pr;
                  @include db;
                  padding-left: 23px;
                  @include transition(all .3s linear);
                }
              }
            }
          }

          &.active {
            >span.submenu-opener {
              color: $white;
            }
          }

          &.open {
            >span.submenu-opener {
              @include transform(rotate(180deg));
              color: darken($color-turquoise, 5%);
            }

            >a {
              background-color: $color-clouds;
              border-color: darken($color-clouds, 3%);
              border-bottom-color: transparent;
              @include border-bottom-radius(0);
              color: darken($color-turquoise, 5%);

              &.active {
                background-color: $color-turquoise;
                border-color: $color-turquoise;
                color: $white;

                &:hover {
                  background-color: darken($color-turquoise, 3%);
                  border-color: darken($color-turquoise, 3%);
                  color: $white;
                }
              }
            }

            >ul {
              height: auto;
              visibility: visible;
              @include opacity(1);
              background-color: lighten($color-clouds, 3%);
              border-color: darken($color-clouds, 3%);
              color: darken($color-turquoise, 5%);

              >li {
                >a {
                  background-color: lighten($color-clouds, 3%);

                  &:hover {
                    color: darken($color-turquoise, 5%);
                  }
                }
              }
            }

            &.active {
              >span.submenu-opener {
                color: $white;
              }

              >ul {
                border-color: darken($color-turquoise, 3%);

                >li {
                  >a {
                    &.active {
                      background-color: darken($color-clouds, 2%);
                      color: darken($color-turquoise, 5%);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ps__rail-y {
        z-index: 2;
      }

      >.overlay {
        @include pf;
        @include dn;
        top: 0;
        left: 0;
        @include wh100;
        z-index: 1;
      }
    }

    >main {
      @include pf;
      @include db;
      top: 0;
      left: 0;
      padding: $header-height + 49px 0 $footer-height $aside-width;
      @include wh100;
      @include transitions(all .3s ease);
      z-index: 1;

      .bg-primary {
        background-color: $brand-primary-color;

        &.hovered {
          &:hover {
            background-color: darken($brand-primary-color, 10%);
          }
        }
      }

      .bg-secondary {
        background-color: $brand-secondary-color;

        &.hovered {
          &:hover {
            background-color: darken($brand-secondary-color, 10%);
          }
        }
      }

      .bg-secondary-light {
        background-color: $brand-secondary-light-color;

        &.hovered {
          &:hover {
            background-color: darken($brand-secondary-light-color, 10%);
          }
        }
      }

      .bg-alt {
        background-color: $brand-alt-color;

        &.hovered {
          &:hover {
            background-color: darken($brand-alt-color, 10%);
          }
        }
      }

      .bg-dark {
        background-color: $brand-dark-color;

        &.hovered {
          &:hover {
            background-color: darken($brand-dark-color, 10%);
          }
        }
      }

      .bg-turquoise {
        background-color: $color-turquoise;

        &.hovered {
          &:hover {
            background-color: darken($color-turquoise, 10%);
          }
        }
      }

      .bg-green-sea {
        background-color: $color-green-sea;

        &.hovered {
          &:hover {
            background-color: darken($color-green-sea, 10%);
          }
        }
      }

      .bg-emerald {
        background-color: $color-emerald;

        &.hovered {
          &:hover {
            background-color: darken($color-emerald, 10%);
          }
        }
      }

      .bg-nephritis {
        background-color: $color-nephritis;

        &.hovered {
          &:hover {
            background-color: darken($color-nephritis, 10%);
          }
        }
      }

      .bg-peter-river {
        background-color: $color-peter-river;

        &.hovered {
          &:hover {
            background-color: darken($color-peter-river, 10%);
          }
        }
      }

      .bg-belize-hole {
        background-color: $color-belize-hole;

        &.hovered {
          &:hover {
            background-color: darken($color-belize-hole, 10%);
          }
        }
      }

      .bg-wet-asphalt {
        background-color: $color-wet-asphalt;

        &.hovered {
          &:hover {
            background-color: darken($color-wet-asphalt, 10%);
          }
        }
      }

      .bg-midnight-blue {
        background-color: $color-midnight-blue;

        &.hovered {
          &:hover {
            background-color: darken($color-midnight-blue, 10%);
          }
        }
      }

      .bg-amethyst {
        background-color: $color-amethyst;

        &.hovered {
          &:hover {
            background-color: darken($color-amethyst, 10%);
          }
        }
      }

      .bg-wisteria {
        background-color: $color-wisteria;

        &.hovered {
          &:hover {
            background-color: darken($color-wisteria, 10%);
          }
        }
      }

      .bg-sunflower {
        background-color: $color-sunflower;

        &.hovered {
          &:hover {
            background-color: darken($color-sunflower, 10%);
          }
        }
      }

      .bg-orange {
        background-color: $color-orange;

        &.hovered {
          &:hover {
            background-color: darken($color-orange, 10%);
          }
        }
      }

      .bg-carrot {
        background-color: $color-carrot;

        &.hovered {
          &:hover {
            background-color: darken($color-carrot, 10%);
          }
        }
      }

      .bg-pumpkin {
        background-color: $color-pumpkin;

        &.hovered {
          &:hover {
            background-color: darken($color-pumpkin, 10%);
          }
        }
      }

      .bg-alizarin {
        background-color: $color-alizarin;

        &.hovered {
          &:hover {
            background-color: darken($color-alizarin, 10%);
          }
        }
      }

      .bg-pomegranate {
        background-color: $color-pomegranate;

        &.hovered {
          &:hover {
            background-color: darken($color-pomegranate, 10%);
          }
        }
      }

      .bg-clouds {
        background-color: $color-clouds;

        &.hovered {
          &:hover {
            background-color: darken($color-clouds, 10%);
          }
        }
      }

      .bg-silver {
        background-color: $color-silver;

        &.hovered {
          &:hover {
            background-color: darken($color-silver, 10%);
          }
        }
      }

      .bg-concrete {
        background-color: $color-concrete;

        &.hovered {
          &:hover {
            background-color: darken($color-concrete, 10%);
          }
        }
      }

      .bg-asbestos {
        background-color: $color-asbestos;

        &.hovered {
          &:hover {
            background-color: darken($color-asbestos, 10%);
          }
        }
      }

      .app-breadcrumb {
        @include pf;
        @include db;
        top: $header-height;
        left: 0;
        padding-left: $aside-width;
        width: 100%;
        @include transitions(all .3s ease);
        z-index: 1;
      }

      .content-container {
        @include pr;
        padding-top: 15px;
        z-index: 2;

        .profile-picture {
          @include pr;
          @include db;
          max-width: 150px;

          >img {
            @include img-fluid;
          }
        }

        .not-found-block {
          @include pr;
          @include db;
          padding: 30px;
          text-align: center;
          color: $color-silver;

          >svg {
            @include pr;
            @include db;
            margin: auto auto 15px;
            width: 50px;
            height: 50px;
          }

          >span {
            @include pr;
            @include db;
          }
        }

        table.table-list {
          >thead {
            background-color: $light;
          }

          th,
          td {
            vertical-align: middle;

            &.date {
              width: 180px;
              max-width: 180px;
              text-align: center;
            }

            &.actions {
              width: 160px;
              max-width: 160px;
              text-align: center;
            }
          }
        }

        .table-red {
          background-color: hsl(0, 40%, 80%);
          color: rgba(255, 255, 255, 0.7);
        }

        .table-orange {
          background-color: hsl(30, 90%, 80%);
          color: $white;
        }

        .table-green {
          background-color: hsl(120, 20%, 85%);
          color: #fff;
        }

        .table-green td time {
          color: black;
        }
        .table-orange td time {
          color: black;
        }
        .table-red td time {
          color: black;
        }



        .content-view {
          .pictures {
            @include pr;

            .item {
              @include pr;
              @include db;
              margin: auto;
              @include w100;

              >img {
                @include pr;
                @include img-fluid;
                @include transitions(all .3s linear);
                z-index: 1;
              }

              >.btn {
                @include pa;
                top: 25px;
                right: 25px;
                visibility: visible;
                @include opacity(.5);
                @include transitions(all .3s linear);
                z-index: 2;
              }

              &:hover {
                >img {
                  border-color: $color-asbestos;
                }

                >.btn {
                  visibility: visible;
                  @include opacity(1);
                }
              }
            }
          }

          img {
            @include img-fluid;
          }
        }

        .content-pagination {
          max-width: 160px;
        }

        .dashboard-indicators {
          >.col {
            margin-bottom: 25px;

            >.item {
              @include pr;
              @include db;
              padding: 10px;
              height: 90px;
              color: $white;
              text-decoration: none;
              @include transitions(all .3s linear);

              >span {
                font-size: 16px;
              }

              >strong {
                @include pa;
                @include db;
                bottom: 10px;
                right: 10px;
                font-size: 38px;
                line-height: 30px;
              }
            }
          }
        }
      }

      .ps__rail-y {
        z-index: 3;
      }
    }

    >.auth-container {
      @include pr;
      @include db;
      margin: auto;
      padding: 15px;
      width: 100%;
      max-width: 380px;
      border: 1px solid $border-color;
      @include border-radius($border-radius);

      >.logo {
        @include pr;
        @include db;
        margin: auto;
        width: 150px;
      }

      >form {
        @include pr;
        @include db;
      }
    }

    >.error-container {
      @include pr;
      @include db;
      margin: auto;
      padding: 15px;
      width: 100%;
      max-width: 380px;
      border: 1px solid $border-color;
      @include border-radius($border-radius);

      >.logo {
        @include pr;
        @include db;
        margin: auto;
        width: 150px;
      }

      >form {
        @include pr;
        @include db;
      }
    }

    >footer {
      @include pf;
      bottom: 0;
      left: 0;
      @include w100;
      height: $footer-height;
      border-top: 1px solid $footer-bg-color;
      font-size: 13px;
      text-align: center;
      color: $footer-text-color;
      z-index: 3;
    }
  }

  .menu-items {
    display: flex;
    padding: 0 0 0 $aside-width;
    align-items: center;
    width: 85%;
    justify-content: space-between;
    transition: all 0.3s ease;
  }

  .reportLogo{
    display: flex;
    padding: 0 0 0 $aside-width;
    align-items: center;
    width: 85%;
    justify-content: space-between;
    top: 0;
  }

  &.aside-toggle {
    .menu-items {
      padding: 0 0 0 3%;
    }
  }


  .header-shifted {
    transform: translateX(250px);
    transition: transform 0.3s ease;
  }

  &.aside-toggle {
    >#root {
      >header {
        >nav.navbar {
          >.aside-toggle {
            background-color: darken($brand-dark-color, 5%);

            >svg {
              color: darken($white, 10%);
            }

            &:hover {
              background-color: darken($brand-dark-color, 5%);

              >svg {
                color: $white;
              }
            }
          }
        }
      }

      >aside {
        left: -$aside-width;
      }

      >main {
        padding-left: 0;

        .app-breadcrumb {
          padding-left: 0;
        }
      }
    }
  }
}

.ydc {
  max-width: 80% !important;
  min-height: 100% !important;
}

@import "responsive";