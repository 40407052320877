@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "variables";
@import "mixins";

@include media-breakpoint-down(md) {
  body {
    > #root {
      > aside {
        left: -$aside-width;
      }

      > main {
        padding-left: 0;

        .app-breadcrumb {
          padding-left: 0;
        }
      }
    }

    &.aside-toggle {
      > #root {
        > aside {
          left: 0;
          @include box-shadow(0 2px 10px -3px rgba(0, 0, 0, .25));

          > .overlay {
            @include db;
          }
        }
      }
    }
  }
}
