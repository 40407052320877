@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "variables";
@import "mixins";

// Bootstrap.
ol.breadcrumb {
  @include border-radius(0);
  background-color: $aside-bg-color;
  border-bottom: 1px solid $border-color;

  > li.breadcrumb-item {
    > a {
      text-decoration: none;
      color: lighten($text-color, 15%);
      @include transitions(all .3s linear);

      > svg {
        margin: -2px 5px 0 0;
        @include transitions(all .3s linear);
      }

      > span {
        text-decoration: none;
        color: lighten($text-color, 15%);
        @include transitions(all .3s linear);
      }

      &:hover {
        color: $text-color;

        > svg,
        > span {
          color: $text-color;
        }
      }
    }
  }
}

.dropdown-menu {
  border-color: $border-color;

  > .dropdown-item {
    @include pr;
    padding: 6px 12px;
    color: lighten($text-color, 10%);
    @include transitions(all .3s linear);

    > svg {
      margin: -2px 5px 0 0;
      @include transitions(all .3s linear);
    }

    > span.text {
      @include transitions(all .3s linear);
    }

    &:hover {
      color: $text-color;

      > svg,
      > span.text {
        color: $text-color;
      }
    }

    &.active {
      background-color: $brand-alt-color;
      color: $white !important;

      > svg,
      > span.text {
        color: $white !important;
      }
    }
  }

  &.bordered {
    > .dropdown-item {
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.form-alert {
  @include pr;

  > p {
    @include mp-reset;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.btn {
  &.btn-icon {
    width: 38px;
    height: 38px;

    > svg {
      margin: -2px auto auto -1px;
    }

    &.btn-sm {
      width: 30px;
      height: 30px;

      > svg {
        margin: -3px auto auto -1px;
      }
    }
  }

  &.with-icon {
    > svg {
      margin: -2px 0 0;
    }

    > span {
      margin-left: 5px;
    }
  }

  &.btn-warning {
    color: $white;

    > svg,
    > span {
      color: $white;
    }
  }
}

.custom-file-input-container {
  @include pr;

  > .file-list {
    @include pr;
    @include clearfix;

    > .item {
      @include pr;
      @include fl;
      margin: 0 5px 5px 0;
      width: 120px;
      height: 100px;
      @include bg-size(cover);
      border: 1px solid $border-color;
      @include border-radius($border-radius);
    }
  }

  > .custom-file {
    &.dropzone {
      @include pr;
      @include w100;
      min-height: 120px;

      > .custom-file-input {
        @include h100;
      }

      > .custom-file-label {
        height: 100%;

        &::after {
          top: 50%;
          margin-top: -18px;
          width: 100%;
          background-color: transparent;
          border: none;
          text-align: center;
          color: $color-asbestos;
        }
      }
    }
  }
}

.card {
  margin-bottom: 15px;

  > .card-header,
  > .card-body,
  > .card-footer {
    padding: 15px;
  }

  > .card-body {
    > .content-view {
      padding-top: 10px;

      > dl {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Perfect scrollbar.
.scrollbar-container {
  position: relative;
  height: 100%;

  > .ps__rail-y {
    width: 10px;

    > .ps__thumb-y {
      right: auto;
      width: 10px;
      @include border-radius(0);
    }

    &:hover {
      background-color: darken($color-clouds, 5%);

      > .ps__thumb-y {
        background-color: $color-silver;
      }
    }
  }
}

// Wysiwyg
.sun-editor-editable {
  font-size: 15px;
}

// React-select
.basic-multi-select {
  z-index: 99;

  > .select__control {
    &.is-focused {
      border-color: $border-color !important;
    }
  }
}
